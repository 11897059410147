import sha256 from 'crypto-js/sha256';
import hmacSha256 from 'crypto-js/hmac-sha256';

const Algorithm = 'SDK-HMAC-SHA256';
const HeaderXDate = 'X-Sdk-Date';
const HeaderAuthorization = 'Authorization';
const HeaderContentSha256 = 'x-sdk-content-sha256';
const hexTable = new Array(256);
for (var i = 0; i < 256; ++i) hexTable[i] = '%' + ((i < 16 ? '0' : '') + i.toString(16)).toUpperCase();
const noEscape = '00000000000000000000000000000000000000000000011011111111110000000111111111111111111111111110000101111111111111111111111111100010';

function urlEncode(str) {
  if (typeof str !== 'string') {
    if (typeof str === 'object') str = String(str);
    else str += '';
  }
  var out = '';
  var lastPos = 0;

  for (var i = 0; i < str.length; ++i) {
    var c = str.charCodeAt(i);

    // ASCII
    if (c < 0x80) {
      if (noEscape[c] === '1') continue;
      if (lastPos < i) out += str.slice(lastPos, i);
      lastPos = i + 1;
      out += hexTable[c];
      continue;
    }

    if (lastPos < i) out += str.slice(lastPos, i);

    // Multi-byte characters ...
    if (c < 0x800) {
      lastPos = i + 1;
      out += hexTable[0xc0 | (c >> 6)] + hexTable[0x80 | (c & 0x3f)];
      continue;
    }
    if (c < 0xd800 || c >= 0xe000) {
      lastPos = i + 1;
      out += hexTable[0xe0 | (c >> 12)] + hexTable[0x80 | ((c >> 6) & 0x3f)] + hexTable[0x80 | (c & 0x3f)];
      continue;
    }
    // Surrogate pair
    ++i;

    if (i >= str.length) throw new errors.URIError('ERR_INVALID_URI');

    var c2 = str.charCodeAt(i) & 0x3ff;

    lastPos = i + 1;
    c = 0x10000 + (((c & 0x3ff) << 10) | c2);
    out +=
      hexTable[0xf0 | (c >> 18)] +
      hexTable[0x80 | ((c >> 12) & 0x3f)] +
      hexTable[0x80 | ((c >> 6) & 0x3f)] +
      hexTable[0x80 | (c & 0x3f)];
  }
  if (lastPos === 0) return str;
  if (lastPos < str.length) return out + str.slice(lastPos);
  return out;
}

function HttpRequest(method, url, headers, body) {
  if (method === undefined) {
    this.method = '';
  } else {
    this.method = method;
  }
  if (url === undefined) {
    this.host = '';
    this.uri = '';
    this.query = {};
  } else {
    setHttpRequestParams(this, url);
  }
  if (headers === undefined) {
    this.headers = {};
  } else {
    this.headers = headers;
  }
  if (body === undefined) {
    this.body = '';
  } else {
    this.body = body;
  }
}

function setHttpRequestParams(self, url) {
  self.query = {};
  var host, path;
  var i = url.indexOf('://');
  if (i !== -1) {
    url = url.substr(i + 3);
  }
  var i = url.indexOf('?');
  if (i !== -1) {
    var query_str = url.substr(i + 1);
    url = url.substr(0, i);
    var spl = query_str.split('&');
    for (var i in spl) {
      var kv = spl[i];
      var index = kv.indexOf('=');
      var key, value;
      if (index >= 0) {
        key = kv.substr(0, index);
        value = kv.substr(index + 1);
      } else {
        key = kv;
        value = '';
      }
      if (key !== '') {
        key = decodeURI(key);
        value = decodeURI(value);
        if (self.query[key] === undefined) {
          self.query[key] = [value];
        } else {
          self.query[key].push(value);
        }
      }
    }
  }
  var i = url.indexOf('/');
  if (i === -1) {
    host = url;
    path = '/';
  } else {
    host = url.substr(0, i);
    path = url.substr(i);
  }
  self.host = host;
  self.uri = decodeURI(path);
}

function findHeader(r, header) {
  for (var k in r.headers) {
    if (k.toLowerCase() === header.toLowerCase()) {
      return r.headers[k];
    }
  }
  return null;
}

// Build a CanonicalRequest from a regular request string
function CanonicalRequest(r, signedHeaders, crypto_wrapper) {
  var hexencode = findHeader(r, HeaderContentSha256);
  if (hexencode === null) {
    var data = RequestPayload(r);
    hexencode = crypto_wrapper.HexEncodeSHA256Hash(data);
  }
  return (
    r.method +
    '\n' +
    CanonicalURI(r) +
    '\n' +
    CanonicalQueryString(r) +
    '\n' +
    CanonicalHeaders(r, signedHeaders) +
    '\n' +
    signedHeaders.join(';') +
    '\n' +
    hexencode
  );
}

function CanonicalURI(r) {
  var pattens = r.uri.split('/');
  var uri = [];
  for (var k in pattens) {
    var v = pattens[k];
    uri.push(urlEncode(v));
  }
  var urlpath = uri.join('/');
  if (urlpath[urlpath.length - 1] !== '/') {
    urlpath = urlpath + '/';
  }
  return urlpath;
}

function CanonicalQueryString(r) {
  var keys = [];
  for (var key in r.query) {
    keys.push(key);
  }
  keys.sort();
  var a = [];
  for (var i in keys) {
    var key = urlEncode(keys[i]);
    var value = r.query[keys[i]];
    if (Array.isArray(value)) {
      value.sort();
      for (var iv in value) {
        a.push(key + '=' + urlEncode(value[iv]));
      }
    } else {
      a.push(key + '=' + urlEncode(value));
    }
  }
  return a.join('&');
}

function CanonicalHeaders(r, signedHeaders) {
  var headers = {};
  for (var key in r.headers) {
    headers[key.toLowerCase()] = r.headers[key];
  }
  var a = [];
  for (var i in signedHeaders) {
    var value = headers[signedHeaders[i]];
    a.push(signedHeaders[i] + ':' + value.trim());
  }
  return a.join('\n') + '\n';
}

function SignedHeaders(r) {
  var a = [];
  for (var key in r.headers) {
    a.push(key.toLowerCase());
  }
  a.sort();
  return a;
}

function RequestPayload(r) {
  return r.body;
}

// Create a "String to Sign".
function StringToSign(canonicalRequest, t, crypto_wrapper) {
  var bytes = crypto_wrapper.HexEncodeSHA256Hash(canonicalRequest);
  return Algorithm + '\n' + t + '\n' + bytes;
}

// Create the HWS Signature.
function SignStringToSign(stringToSign, signingKey, crypto_wrapper) {
  return crypto_wrapper.hmacsha256(signingKey, stringToSign);
}

// Get the finalized value for the "Authorization" header.  The signature
// parameter is the output from SignStringToSign
function AuthHeaderValue(signature, Key, signedHeaders) {
  return Algorithm + ' Access=' + Key + ', SignedHeaders=' + signedHeaders.join(';') + ', Signature=' + signature;
}

function twoChar(s) {
  if (s >= 10) {
    return '' + s;
  } else {
    return '0' + s;
  }
}

function getTime() {
  var date = new Date();
  return (
    '' +
    date.getUTCFullYear() +
    twoChar(date.getUTCMonth() + 1) +
    twoChar(date.getUTCDate()) +
    'T' +
    twoChar(date.getUTCHours()) +
    twoChar(date.getUTCMinutes()) +
    twoChar(date.getUTCSeconds()) +
    'Z'
  );
}

function getSigner(crypto_wrapper) {
  function Signer() {
    this.Key = '';
    this.Secret = '';
  }
  Signer.prototype.Sign = function (r) {
    var headerTime = findHeader(r, HeaderXDate);
    if (headerTime === null) {
      headerTime = getTime();
      r.headers[HeaderXDate] = headerTime;
    }
    if (r.method !== 'PUT' && r.method !== 'PATCH' && r.method !== 'POST') {
      r.body = '';
    }
    var queryString = CanonicalQueryString(r);
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    var options = {
      hostname: r.host,
      path: encodeURI(r.uri) + queryString,
      method: r.method,
      headers: r.headers,
    };
    if (findHeader(r, 'host') === null) {
      r.headers.host = r.host;
    }
    var signedHeaders = SignedHeaders(r);
    var canonicalRequest = CanonicalRequest(r, signedHeaders, crypto_wrapper);
    var stringToSign = StringToSign(canonicalRequest, headerTime, crypto_wrapper);
    var signature = SignStringToSign(stringToSign, this.Secret, crypto_wrapper);
    options.headers[HeaderAuthorization] = AuthHeaderValue(signature, this.Key, signedHeaders);
    return options;
  };
  return Signer;
}



function factory (crypto_wrapper) {
  return {
    HttpRequest: HttpRequest,
    Signer: getSigner(crypto_wrapper),
    urlEncode: urlEncode,
    findHeader: findHeader,
    SignedHeaders: SignedHeaders,
    CanonicalRequest: CanonicalRequest,
    StringToSign: StringToSign,
  };
};

function Signer() {
    // Browser
    var crypto_wrapper = {
      hmacsha256: function (keyByte, message) {
        return hmacSha256(message, keyByte)
      },
      HexEncodeSHA256Hash: function (body) {
        return sha256(body);
      },
    };
    return factory(crypto_wrapper);
}


export { Signer };
