import { DataQuery, DataSourceJsonData } from '@grafana/data';

export interface LTSQuery extends DataQuery {
  query?: string | undefined;
  queryText?: string;
  constant: number;
  yColumn: string;
  xColumn: string;
  streamId: string;
}

export const defaultQuery: Partial<LTSQuery> = {
  queryText: 'select count(*) as myCount, __time group by __time',
  constant: 6.5,
  xColumn: "__time",
  yColumn: "myCount",
  streamId: "",
};

/**
 * These are options configured for each DataSource instance
 */
export interface MyDataSourceOptions extends DataSourceJsonData {
  endpoint?: string;
  projectId?: string;
  streamId?: string;
  secretKey?: string;
  accessKey?: string;
}

export interface LTSSelect {
  value: string;
  label: string;
}