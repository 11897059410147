import {
  DataQueryRequest,
  DataSourceInstanceSettings,
} from '@grafana/data';
import { MyDataSourceOptions, LTSQuery } from './types';
import { DataSourceWithBackend, getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import _ from 'lodash';
import { Signer } from '../lib/signerHws.js'

export class DataSource extends DataSourceWithBackend<LTSQuery, MyDataSourceOptions> {

  url: string;
  projectId: string | undefined;
  endpoint: string;
  streamId: string;
  ak: string;
  sk: string;
  constructor(instanceSettings: DataSourceInstanceSettings<MyDataSourceOptions>) {
    super(instanceSettings);
    this.url = instanceSettings.url === undefined ? '' : instanceSettings.url;
    this.projectId = instanceSettings.jsonData.projectId || "";
    this.endpoint = instanceSettings.jsonData.endpoint || "";
    this.ak = instanceSettings.jsonData.accessKey || '';
    this.sk = instanceSettings.jsonData.secretKey || '';
    this.streamId = instanceSettings.jsonData.streamId || '';
  }

  query(options: DataQueryRequest<LTSQuery>) {
    options.targets.forEach((q: LTSQuery) => {
      q.streamId = getTemplateSrv().replace(q.streamId, options.scopedVars) || this.streamId;
      q.query = getTemplateSrv().replace(q.query, options.scopedVars)
      q.queryText = getTemplateSrv().replace(q.queryText, options.scopedVars)
    });
    return super.query(options);
  }

  metricFindQuery(query: LTSQuery, options?: any) {
    const data = {
      from: options.range.from.valueOf().toString(),
      to: options.range.to.valueOf().toString(),
      queries: [
        {
          // datasource: this.name,
          datasource: {type: this.type, uid: this.uid},
          datasourceId: this.id,
          streamId: this.streamId,
          query: getTemplateSrv().replace(query.query, options.scopedVars),
          queryText: getTemplateSrv().replace(query.query, options.scopedVars)
        },
      ],
    };
    return getBackendSrv()
      .post('/api/ds/query', data)
      .then(response => {
        return response.results.A.frames[0].data.values[0];
      })
      .then(mapToTextValue);
  }

  rest() {
    const signer =  Signer()
    const sig = new signer.Signer()
    sig.Key = this.ak;
    sig.Secret = this.sk;
    const headers = {
      "Content-Type": "application/json;charset=UTF-8"
    };
    const method = 'GET';
    const url = `https://${this.endpoint}/v2/${this.projectId}/log-streams`;
    const uri = '/log-streams'
    const r= new signer.HttpRequest(method, url, headers);
    const opt = sig.Sign(r);
    return getBackendSrv().datasourceRequest({
      url: this.url + '/lts' + uri,
      method: opt.method,
      headers: opt.headers,
    })
  }
}

export function mapToTextValue(result: any) {
  return _.map(result, (d, i) => {
    if (d && d.text && d.value) {
      return { text: d.text, value: d.value };
    } else if (_.isObject(d)) {
      return { text: d, value: i };
    }
    return { text: d, value: d };
  });
}
