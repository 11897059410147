import defaults from 'lodash/defaults';

import React, { ChangeEvent, PureComponent } from 'react';
import { Label, LegacyForms, Select, SelectCommonProps } from '@grafana/ui';
import { QueryEditorProps } from '@grafana/data';
import { DataSource } from './datasource';
import { defaultQuery, MyDataSourceOptions, LTSQuery } from './types';
import './styles/query-editor.css';

const { FormField } = LegacyForms;
let time;

type Props = QueryEditorProps<DataSource, LTSQuery, MyDataSourceOptions>;

export class QueryEditor extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.getStreams();
  }

  onQueryTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, query } = this.props;
    onChange({ ...query, queryText: event.target.value, streamId: this.streamsModel.value });
  };

  onConstantChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, query, onRunQuery } = this.props;
    onChange({ ...query, constant: parseFloat(event.target.value), streamId: this.streamsModel.value });
    onRunQuery();
  };

  onYColumnTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, query } = this.props;
    onChange({ ...query, yColumn: event.target.value, streamId: this.streamsModel.value });
  };

  onXColumnTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, query, onRunQuery } = this.props;
    onChange({ ...query, xColumn: event.target.value, streamId: this.streamsModel.value });
    if(time) {
      clearTimeout(time)
    }
    time = setTimeout(() => {
      onRunQuery();
    }, 500);
  };

  streamsModel: SelectCommonProps<any> = {
    value: '',
    options: [],
    onChange: (data) => {
      this.streamsModel.value = data.value;
      const { onChange, query, onRunQuery } = this.props;
      onChange({ ...query, streamId: data.value });
      onRunQuery();
    }
  }

  getStreams = () => {
    const { datasource } = this.props;
    datasource.rest().then(result => {
      const logStreamsInfo = result.data?.log_streams || [];
      this.streamsModel.options = logStreamsInfo.map(item => {
        return {
          value: item.log_stream_id,
          label: item.log_stream_name,
        }
      })
      this.forceUpdate();
      this.props.onRunQuery();
    })
  }



  render() {
    const query = defaults(this.props.query, defaultQuery);
    const { queryText, yColumn } = query;
    const xColumn = query.xColumn;
    const streamId = query.streamId || this.streamsModel.value || this.props.datasource.streamId || '';
    return (
      <div className="query-editor-container">
        <div className="gf-form">
          <FormField
            labelWidth={8}
            value={queryText || 'select *'}
            onChange={this.onQueryTextChange}
            label="Query Text"
            tooltip="Not used yet"
          />
        </div>
        <div className="gf-form">
          <FormField
            labelWidth={8}
            value={yColumn}
            onChange={this.onYColumnTextChange}
            label="Y - column"
            tooltip="支持将维度列聚合，格式为 维度列:数据列"
          />
          <FormField
            labelWidth={8}
            value={xColumn}
            onChange={this.onXColumnTextChange}
            label="X - column [time]"
          />
        </div>
        <div className='gf-streams-wrap'>
          <Label className='gf-form-label gf-streams-label' 
            children='Log Streams'></Label>
          <Select
            value={streamId}
            options={this.streamsModel.options}
            onChange={this.streamsModel.onChange}
            placeholder="请选择日志流"
          />
        </div>
      </div>
      
    );
  }
}
