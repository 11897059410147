import React, { ChangeEvent, PureComponent } from 'react';
import { MyDataSourceOptions, LTSQuery } from './types';

import { LegacyForms } from '@grafana/ui';
import { QueryEditorProps } from '@grafana/data';
import { DataSource } from './datasource';
const { FormField } = LegacyForms;

type Props = QueryEditorProps<DataSource, LTSQuery, MyDataSourceOptions>;
export class VariableQueryEditor extends PureComponent<Props> {
  onQueryTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, query } = this.props;
    onChange({ ...query, query: event.target.value });
  };

  render() {
    const { query } = this.props.query;

    return (
      <>
        <div className="gf-form-inline">
          <FormField labelWidth={10} inputWidth={30} value={query} onChange={this.onQueryTextChange} label="Query" />
        </div>
      </>
    );
  }
}
