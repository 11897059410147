import React, { ChangeEvent, PureComponent } from 'react';
import { LegacyForms } from '@grafana/ui';
import { DataSourcePluginOptionsEditorProps } from '@grafana/data';
import { MyDataSourceOptions } from './types';

const { FormField } = LegacyForms;

export class ConfigEditor extends PureComponent<DataSourcePluginOptionsEditorProps<MyDataSourceOptions>, any> {
  onEndPointChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { onOptionsChange, options } = this.props;
    const jsonData = {
      ...options.jsonData,
      endpoint: event.target.value,
    };
    onOptionsChange({ ...options, jsonData });
  };

  onProjectChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { onOptionsChange, options } = this.props;
    const jsonData = {
      ...options.jsonData,
      projectId: event.target.value,
    };
    onOptionsChange({ ...options, jsonData });
  };

  onStreamChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { onOptionsChange, options } = this.props;
    const jsonData = {
      ...options.jsonData,
      streamId: event.target.value,
    };
    onOptionsChange({ ...options, jsonData });
  };

  // Secure field (only sent to the backend)
  onAccessKeyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { onOptionsChange, options } = this.props;
    const jsonData = {
      ...options.jsonData,
      accessKey: event.target.value,
    };
    onOptionsChange({ ...options, jsonData });
  };

  // Secure field (only sent to the backend)
  onSecretKeyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { onOptionsChange, options } = this.props;
    const jsonData = {
      ...options.jsonData,
      secretKey: event.target.value,
    };
    onOptionsChange({ ...options, jsonData });
  };

  render() {
    const { options } = this.props;
    const { jsonData } = options;

    return (
      <div>
        <div className="gf-form-group">
          <h3 className="page-heading">log service details</h3>

          <div className="gf-form">
            <FormField
              label="EndPoint"
              labelWidth={8}
              inputWidth={20}
              onChange={this.onEndPointChange}
              value={jsonData.endpoint || ''}
              placeholder="endpoint"
            />
          </div>
          
          <div className="gf-form-inline">
            <div className="gf-form">
              <FormField
                label="projectId"
                labelWidth={8}
                inputWidth={20}
                onChange={this.onProjectChange}
                value={jsonData.projectId || ''}
                placeholder="json field returned to frontend"
              />
              <FormField
                label="streamId"
                labelWidth={8}
                inputWidth={22}
                onChange={this.onStreamChange}
                value={jsonData.streamId || ''}
                placeholder="json field returned to frontend"
              />
            </div>
          </div>

          <div className="gf-form-inline">
            <div className="gf-form">
              <FormField
                label="AccessKeyId"
                labelWidth={8}
                inputWidth={20}
                onChange={this.onAccessKeyChange}
                value={jsonData.accessKey || ''}
                placeholder="json field returned to frontend"
              />
              <FormField
                label="SecretAccessKey"
                labelWidth={8}
                inputWidth={22}
                onChange={this.onSecretKeyChange}
                value={jsonData.secretKey || ''}
                placeholder="json field returned to frontend"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
